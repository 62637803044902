<template>
  <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
    <DragAndDropFile
      exampleFileUrl="/media/opalean/Example Operations Import File.xlsx"
      maxFileLines="300"
      maxHoursFileAge="48"
      exampleFileLabel="Example Operations Import File"
      @fileDropped="handleFile"
      v-if="!fileIsDropped"
    />

    <ProcessFile v-else :selectedFile="selectedFile" @fileDropped="cancelProcess" />
  </div>
</template>

<script>
import DragAndDropFile from "@/view/content/neoopatrace/components/DragAndDrop/DragAndDropFile.vue";
import ProcessFile from "@/view/content/neoopatrace/components/importOperation/ProcessFile.vue";

export default {
  title() {
    return this.$gettext("route.operations.import");
  },
  name: "Import",
  components: {
    DragAndDropFile,
    ProcessFile,
  },
  data() {
    return {
      fileIsDropped: false,
      selectedFile: null,
    };
  },
  methods: {
    /**
     * Handles the 'fileDropped' event to indicate that the file has been dropped.
     * @param file - The dropped file
     */
    handleFile(fileContent) {
      this.fileIsDropped = true;
      this.selectedFile = fileContent;
    },
    /**
     * Cancels the import process.
     */
    cancelProcess() {
      this.fileIsDropped = false;
      this.selectedFile = null;
    },
  },
};
</script>
