<template>
  <b-card text-variant="opalean-gray-dark" class="card-custom gutter-b w-100 h-100">
    <b-card-text class="flex-column align-items-center h-100">
      <!-- begin:: Table of operations -->
      <div class="table-container" style="overflow: auto; height: 40vh">
        <table class="table-custom table-striped table-hover">
          <thead>
            <tr>
              <!-- Placeholder for row number column -->
              <th scope="col"></th>
              <!-- Dynamic table headers -->
              <th class="font-size-h6" v-for="(header, index) in headers" :key="index" :id="'header-' + index" :style="headerClasses[header]">
                {{ header }}{{ MandatoryColumns.has(header) ? "*" : "" }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="rows.length > 0">
              <!-- Display rows if there are data -->
              <tr v-for="(_, rowIndex) in rows" :key="rowIndex" :style="rowClasses[rowIndex]" ref="bodyRows">
                <td class="left-stick index-rows font-size-lg" :style="rowClasses[rowIndex]" scope="row">{{ rowIndex + 1 }}</td>
                <td class="cell-rows" v-for="(header, columnIndex) in headers" :key="columnIndex" :style="cellClasses[`${rowIndex}-${header}`]">
                  <div
                    class="cell-operation font-size-lg"
                    v-if="
                      columnsPalletCanNotBeReturned.has(header) ||
                      header === 'TypeOp' ||
                      (columnsPalletCanNotBeReturned.has(header.slice(1)) && header.startsWith('-')) ||
                      (rows[rowIndex][headers.indexOf('TypeOp')] === 'T' && header.startsWith('-'))
                    "
                  >
                    {{ rows[rowIndex][columnIndex] }}
                  </div>
                  <textarea
                    v-else
                    class="cell-operation font-size-lg"
                    :value="rows[rowIndex][columnIndex]"
                    @input="debouncedUpdate($event, rowIndex, header)"
                  ></textarea>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="100%" class="text-center" style="color: dimgrey; font-style: italic; background-color: white; height: 38vh" v-translate>
                  No data found
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </b-card-text>
    <!-- begin:: Card footer -->
    <template v-slot:footer>
      <div class="d-flex align-items-stretch w-100">
        <!-- begin:: List of errors and warnings -->
        <div class="flex-grow-1 d-flex flex-column">
          <div class="d-flex flex-row align-items-start">
            <button
              class="d-flex align-items-center justify-content-center error-button"
              :class="{ active: processErrors.length + processRowsPalletsErrors.length + processRowsPartnersRoleErrors.length > 0 }"
            >
              <b-icon-exclamation-circle-fill class="text-danger h3 m-2"></b-icon-exclamation-circle-fill>
              <span class="font-size-h5 text-center mr-2">
                {{ processErrors.length + processRowsPalletsErrors.length + processRowsPartnersRoleErrors.length }} <translate>Errors</translate></span
              >
            </button>
            <div class="ml-auto d-flex flex-row align-items-center">
              <button class="d-flex align-items-center justify-content-center action-button action-button-cancel" @click="cancelUpload" style="width: 125px">
                <b-icon icon="x-circle-fill" class="h3 m-2" style="color: #dc3545"></b-icon>
                <span class="font-size-h5 text-center mr-2" v-translate>Cancel</span>
              </button>
              <div class="ml-4"></div>
              <button
                v-if="rows.length >= 0 && processErrors.length === 0 && processRowsPalletsErrors.length === 0 && processRowsPartnersRoleErrors.length === 0"
                class="d-flex align-items-center justify-content-center action-button action-button-upload"
                @click="uploadData()"
                :disabled="isUploading"
                style="width: 125px"
              >
                <b-icon icon="cloud-upload" class="h3 m-2" style="color: #28a745"></b-icon>
                <span class="font-size-h5 text-center mr-2">{{ uploadButtonText }}</span>
              </button>
            </div>
          </div>
          <div class="mb-4"></div>
          <!-- #region Table of errors -->
          <div class="table-container" style="overflow: auto; height: 16vh">
            <table class="table-custom table-striped table-hover">
              <thead>
                <tr>
                  <th class="font-size-h6" scope="col" style="width: 1%; padding: 5px 8px 3px; text-align: center">
                    <b-icon-info-circle-fill class="text-muted"></b-icon-info-circle-fill>
                  </th>
                  <th class="font-size-h6" scope="col" style="width: 15%; padding: 5px 8px 3px"><translate>Error Type</translate></th>
                  <th class="font-size-h6" scope="col" style="padding: 5px 8px 3px"><translate>Description</translate></th>
                  <th class="font-size-h6" scope="col" style="width: 10%; padding: 5px 8px 3px"><translate>Line</translate></th>
                  <th class="font-size-h6" scope="col" style="width: 10%; padding: 5px 8px 3px"><translate>Column</translate></th>
                </tr>
              </thead>
              <tbody>
                <template v-if="processErrors.length + processRowsPalletsErrors.length + processRowsPartnersRoleErrors.length > 0">
                  <tr v-for="(error, index) in sortedErrors" :key="index">
                    <td class="font-size-lg" style="padding: 4px 8px 1px; text-align: center">
                      <b-icon-exclamation-circle-fill class="text-danger"></b-icon-exclamation-circle-fill>
                    </td>
                    <td class="font-size-lg" style="padding: 4px 8px 1px">
                      <span v-translate>Correctable Error</span>
                    </td>
                    <td class="font-size-lg" style="padding: 4px 8px 1px">{{ error.description }}</td>
                    <td class="font-size-lg" style="padding: 4px 8px 1px">{{ error.rowIndex + 1 }}</td>
                    <td class="font-size-lg" style="padding: 4px 8px 1px">{{ error.column }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="100%" class="text-center" style="color: dimgrey; font-style: italic; background-color: white; height: 10vh" v-translate>
                      No errors found
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <!-- #endregion -->
        </div>
      </div>
    </template>
    <!-- end:: Card footer -->
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      isUploading: false,
      ImportStatus: {
        SUCCESS: "success",
        ERROR: "error",
      },
      MandatoryColumns: new Set(["DateOp", "TypeOp", "RefOp", "CMR"]),
      OptionalColumns: new Set([
        "ShipperRef",
        "ShipperName",
        "CarrierRef",
        "CarrierName",
        "SubcontractorRef",
        "SubcontractorName",
        "ReceiverRef",
        "ReceiverName",
      ]),
      PartnerRefs: new Set(["ShipperRef", "CarrierRef", "SubcontractorRef", "ReceiverRef"]),
      PartnerNames: new Set(["ShipperName", "CarrierName", "SubcontractorName", "ReceiverName"]),
      maximumQuantityPallets: 10000,
      columnsPalletCanBeReturned: new Set(),
      columnsPalletCanNotBeReturned: new Set(),
      /** @type {string} */
      myPartnerReference: null,
      partnerReferences: new Set(),
      partnerReferencesData: new Set(),
      myPartner: {
        Reference: null,
        Id: null,
        Name: null,
      },
      existMC: false,
      existMD: false,
      existMT: false,
      existST: false,
      /**@type {Set<string>} */
      prefixesHeadersPallets: new Set(),
      /**@type {Set<string>} */
      headersPallets: new Set(),
      uniqueHeaders: new Set(),
      /** @type {string[]} */
      headers: [],
      /** @type {string[][]} */
      rows: [],
      /** @type {Array<{rowIndex: number, column: string, description: string}>}*/
      processErrors: [],
      /** @type {Array<{rowIndex: number, column: string, description: string}>}*/
      processRowsPalletsErrors: [],
      /** @type {Array<{rowIndex: number, column: string, description: string}>}*/
      processRowsPartnersRoleErrors: [],
    };
  },
  props: {
    selectedFile: {
      type: String,
      required: true,
    },
  },
  watch: {
    selectedFile: {
      handler(csvContent) {
        this.parseCSVContent(csvContent);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["getPartners", "getPallets", "getUser"]),
    uploadButtonText() {
      return this.isUploading ? this.$gettext("Uploading...") : this.$gettext("Upload");
    },
    sortedErrors() {
      return [...this.processErrors, ...this.processRowsPalletsErrors, ...this.processRowsPartnersRoleErrors].sort((a, b) => a.rowIndex - b.rowIndex);
    },
    rowClasses() {
      const rowClasses = {};
      const allErrors = [...this.processRowsPartnersRoleErrors, ...this.processRowsPalletsErrors, ...this.processErrors];
      for (let i = 0, len = allErrors.length; i < len; i++) {
        const error = allErrors[i];
        if (error.rowIndex !== undefined) {
          rowClasses[error.rowIndex] = "background-color: #ffcccc;";
        }
      }
      return rowClasses;
    },
    headerClasses() {
      const headerClasses = {};
      const allErrors = [...this.processRowsPartnersRoleErrors, ...this.processRowsPalletsErrors, ...this.processErrors];
      for (let i = 0, len = allErrors.length; i < len; i++) {
        const error = allErrors[i];
        if (error.column) {
          headerClasses[error.column] = "background-color: #ffcccc;";
        }
      }
      return headerClasses;
    },
    cellClasses() {
      const cellClasses = {};
      const allErrors = [...this.processRowsPartnersRoleErrors, ...this.processRowsPalletsErrors, ...this.processErrors];
      for (let i = 0, len = allErrors.length; i < len; i++) {
        const error = allErrors[i];
        if (error.column && error.rowIndex !== undefined) {
          cellClasses[`${error.rowIndex}-${error.column}`] = "background-color: #f8d7da; border: 2px solid #e74a3b;";
        }
      }
      return cellClasses;
    },
  },

  methods: {
    /** @param {string} csvContent */
    parseCSVContent(csvContent) {
      // Diviser le contenu CSV en lignes (Le contenu est déjà normalisé et les lignes vides sont supprimées)
      const lines = csvContent.split("\n");

      // Extraire la première ligne comme en-tête
      const headerLine = lines[0];

      // Diviser les en-têtes en utilisant "¤" comme séparateur et supprimer les espaces
      this.headers = headerLine.split("¤").map((header) => header.trim());

      // Pré-allouer le tableau des lignes pour une meilleure performance
      this.rows = new Array(lines.length - 1);

      // Parcourir les lignes de données et les diviser par le séparateur "¤"
      for (let i = 1, len = lines.length; i < len; i++) {
        this.rows[i - 1] = lines[i].split("¤");
      }

      // Initialiser les données de traitement
      this.initializeProcessingData();

      // Vérifier la validité des en-têtes
      this.validateCSVHeaders();

      // Traiter les lignes et les colonnes
      this.processRows();
    },

    initializeProcessingData() {
      // Récupérer les en-têtes et créer un ensemble unique d'en-têtes
      const headers = this.headers;
      this.uniqueHeaders = new Set(headers);

      // Vérifier l'existence de références spécifiques dans les en-têtes
      this.existMC = this.uniqueHeaders.has("ShipperRef");
      this.existMD = this.uniqueHeaders.has("ReceiverRef");
      this.existMT = this.uniqueHeaders.has("CarrierRef");
      this.existST = this.uniqueHeaders.has("SubcontractorRef");

      // Initialiser des ensembles pour les palettes
      const headersPallets = new Set();
      const prefixesHeadersPallets = new Set();

      // Remplir les ensembles avec les en-têtes qui commencent par "+" ou "-"
      for (let i = 0; i < headers.length; i++) {
        const header = headers[i];
        if (header.startsWith("+") || header.startsWith("-")) {
          headersPallets.add(header); // Ajouter l'en-tête complet
          prefixesHeadersPallets.add(header.slice(1)); // Ajouter l'en-tête sans le préfixe
        }
      }

      // Stocker les ensembles dans l'objet courant
      this.headersPallets = headersPallets;
      this.prefixesHeadersPallets = prefixesHeadersPallets;

      // Initialiser des ensembles pour les types de palettes
      const columnsPalletCanBeReturned = new Set();
      const columnsPalletCanNotBeReturned = new Set();

      // Remplir les ensembles avec les références de palettes selon leur type
      for (let i = 0; i < this.getPallets.length; i++) {
        const pallet = this.getPallets[i];
        if (pallet.PalletType === "X") {
          columnsPalletCanBeReturned.add(pallet.PalletRef); // Palette qui peut être retournée
        } else if (["L", "P"].includes(pallet.PalletType)) {
          columnsPalletCanNotBeReturned.add(pallet.PalletRef); // Palette qui ne peut pas être retournée
        }
      }

      // Stocker les ensembles de palettes dans l'objet courant
      this.columnsPalletCanBeReturned = columnsPalletCanBeReturned;
      this.columnsPalletCanNotBeReturned = columnsPalletCanNotBeReturned;

      // Initialiser des ensembles pour les références des partenaires
      const partnerReferences = new Set();
      const partnerReferencesData = new Set();

      let myPartnerReference = null; // Pour stocker la référence de mon partenaire
      const userClientID = this.getUser.ClientID; // Récupérer l'ID du client utilisateur

      // Remplir les ensembles avec les références des partenaires
      for (let i = 0; i < this.getPartners.length; i++) {
        const partner = this.getPartners[i];
        partnerReferences.add(partner.Reference); // Ajouter la référence du partenaire
        partnerReferencesData.add({
          Reference: partner.Reference,
          Id: partner.PartnerID,
          Name: partner.Name,
        }); // Ajouter les données du partenaire

        // Vérifier si le partenaire correspond à l'utilisateur actuel
        if (partner.PartnerID === userClientID) {
          myPartnerReference = partner.Reference; // Enregistrer ma référence de partenaire
        }
      }

      // Stocker les références des partenaires dans l'objet courant
      this.partnerReferences = partnerReferences;
      this.partnerReferencesData = partnerReferencesData;

      // Vérifier si ma référence de partenaire a été trouvée
      if (!myPartnerReference) {
        return this.finishProcess(this.ImportStatus.ERROR, "Your clientID has not been found.");
      }

      this.myPartnerReference = myPartnerReference; // Enregistrer ma référence de partenaire si trouvée
    },
    //#region Header Validation
    validateCSVHeaders() {
      const statusError = this.ImportStatus.ERROR;
      const { uniqueHeaders, headers, headersPallets, MandatoryColumns } = this;

      /** @param {boolean} condition, @param {string} errorMessage  */
      const validateCondition = (condition, errorMessage) => {
        if (!condition) {
          return this.finishProcess(statusError, errorMessage);
        }
      };

      // Vérification des colonnes en double
      validateCondition(uniqueHeaders.size === headers.length, "Duplicate columns detected in the CSV file.");

      // Vérification des colonnes obligatoires
      validateCondition(
        Array.from(MandatoryColumns).every((col) => uniqueHeaders.has(col)),
        "Some mandatory columns are missing."
      );
      validateCondition(headersPallets.size > 0, "The '+ReferencePallet' and '-ReferencePallet' fields are mandatory.");

      // Vérification de la validité de tous les en-têtes
      validateCondition(
        headers.every((header) => MandatoryColumns.has(header) || this.OptionalColumns.has(header) || header.startsWith("+") || header.startsWith("-")),
        "Some headers are neither mandatory nor optional."
      );

      // Vérification des références de palettes
      const validPalletReferences = new Set(this.getPallets.map((p) => p.PalletRef));
      const missingPalletReferences = Array.from(this.prefixesHeadersPallets).filter((ref) => !validPalletReferences.has(ref));
      validateCondition(missingPalletReferences.length === 0, `Some pallet references do not exist : ${missingPalletReferences.join(", ")}.`);

      // Vérification de la présence de '+prefix' et '-prefix'
      const invalidPalletsReferences = Array.from(this.prefixesHeadersPallets).filter(
        (prefix) => !uniqueHeaders.has(`+${prefix}`) || !uniqueHeaders.has(`-${prefix}`)
      );
      validateCondition(
        invalidPalletsReferences.length === 0,
        `Invalid pallet references : ${invalidPalletsReferences.join(", ")}. Make sure that each reference has a '+' and '-' column.`
      );

      // Vérification de l'en-tête TypeOp
      const operationTypes = new Set();
      const typeOpIndex = headers.indexOf("TypeOp");
      for (const row of this.rows) {
        const dataTypeOp = row[typeOpIndex];
        validateCondition(["T", "C", "D"].includes(dataTypeOp), "Field TypeOp must be 'T', 'C' or 'D'.");
        operationTypes.add(dataTypeOp);
      }

      const hasTransfertOperation = operationTypes.has("T");
      const hasLoadingOperation = operationTypes.has("C");
      const hasUnloadingOperation = operationTypes.has("D");
      const { existMC, existMD, existMT } = this;

      const ensureMandatoryColumnsPresent = (columns, message) => {
        if (!columns.every((col) => this[`exist${col}`])) {
          return this.finishProcess(statusError, message);
        }
      };

      if (
        (hasTransfertOperation && hasUnloadingOperation && hasLoadingOperation) ||
        (hasTransfertOperation && hasUnloadingOperation) ||
        (hasTransfertOperation && hasLoadingOperation) ||
        (hasUnloadingOperation && hasLoadingOperation)
      ) {
        return ensureMandatoryColumnsPresent(
          ["MT", "MC", "MD"],
          `Columns mandatory must be present : ${existMT ? "" : "CarrierRef"}${existMC ? "" : ", ShipperRef"}${existMD ? "" : ", ReceiverRef"}.`
        );
      } else if (hasTransfertOperation) {
        return ensureMandatoryColumnsPresent(
          ["MC", "MD"],
          `Columns mandatory must be present : ${existMC ? "" : "ShipperRef"}${existMD ? "" : ", ReceiverRef"}.`
        );
      } else if (hasUnloadingOperation) {
        return ensureMandatoryColumnsPresent(
          ["MD", "MT"],
          `Columns mandatory must be present : ${existMD ? "" : "ReceiverRef"}${existMT ? "" : ", CarrierRef"}.`
        );
      } else if (hasLoadingOperation) {
        return ensureMandatoryColumnsPresent(
          ["MC", "MT"],
          `Columns mandatory must be present : ${existMC ? "" : "ShipperRef"}${existMT ? "" : ", CarrierRef"}.`
        );
      }
    },
    //#endregion

    validatePartnersRole(rowIndex) {
      // Supprimer les erreurs existantes dans la ligne : rowIndex
      this.processRowsPartnersRoleErrors = this.processRowsPartnersRoleErrors.filter((error) => error.rowIndex !== rowIndex);

      // Récupérer les informations nécessaires
      const { headers, existMC, existMD, existMT, existST, myPartnerReference, PartnerRefs } = this;
      const row = this.rows[rowIndex];
      const typeOp = row[headers.indexOf("TypeOp")];

      // Récupérer les données des colonnes pertinentes
      const dataMC = existMC ? row[headers.indexOf("ShipperRef")] : null;
      const dataMD = existMD ? row[headers.indexOf("ReceiverRef")] : null;
      const dataMT = existMT ? row[headers.indexOf("CarrierRef")] : null;
      const dataST = existST ? row[headers.indexOf("SubcontractorRef")] : null;

      //#region Vérification des doublons dans les références partenaires

      // Créer un objet pour stocker les valeurs des références partenaires
      const partnerValues = {
        ShipperRef: dataMC,
        ReceiverRef: dataMD,
        CarrierRef: dataMT,
        SubcontractorRef: dataST,
      };

      // On vérifie chaque colonne et sa valeur dans l'objet partnerValues
      Object.entries(partnerValues).forEach(([column, value]) => {
        // On ne traite que les valeurs non vides
        if (value) {
          // On cherche les doublons dans les autres colonnes
          // Cette ligne filtre les références partenaires pour trouver celles qui :
          // 1. Ne sont pas la colonne actuelle (ref !== column)
          // 2. Ont la même valeur que la colonne actuelle (partnerValues[ref] === value)
          const duplicates = PartnerRefs.filter((ref) => ref !== column && partnerValues[ref] === value);

          // Pour chaque doublon trouvé
          duplicates.forEach((duplicateColumn) => {
            // On vérifie si une erreur pour ce doublon existe déjà
            // Cette recherche vérifie si :
            // 1. L'erreur concerne la même ligne (rowIndex)
            // 2. L'erreur concerne la même colonne (duplicateColumn)
            // 3. Le message d'erreur commence par "Duplicate value"
            const existingError = this.processRowsPartnersRoleErrors.find(
              (error) => error.rowIndex === rowIndex && error.column === duplicateColumn && error.description.startsWith("Duplicate value")
            );

            // Si aucune erreur existante n'est trouvée pour ce doublon
            if (!existingError) {
              // On ajoute une nouvelle erreur à la liste
              this.processRowsPartnersRoleErrors.push({
                rowIndex,
                column: duplicateColumn,
                description: `Duplicate value found in ${duplicateColumn}.`,
              });
            }
          });
        }
      });
      //#endregion

      // Vérifications des partenaires
      const isPartnerMC = dataMC === myPartnerReference;
      const isPartnerMD = dataMD === myPartnerReference;
      const isPartnerMT = dataMT === myPartnerReference;

      // Validations par type d'opération
      switch (typeOp) {
        case "C":
          // Vérification pour ShipperRef
          if (!dataMC || (!isPartnerMC && !isPartnerMT)) {
            this.processRowsPartnersRoleErrors.push({
              rowIndex,
              column: "ShipperRef",
              description: dataMC ? `My reference ${myPartnerReference} is mandatory in either ShipperRef or CarrierRef.` : "ShipperRef is mandatory.",
            });
          }

          // Vérification pour CarrierRef
          if (!dataMT || (!isPartnerMC && !isPartnerMT)) {
            this.processRowsPartnersRoleErrors.push({
              rowIndex,
              column: "CarrierRef",
              description: dataMT ? `My reference ${myPartnerReference} is mandatory in either ShipperRef or CarrierRef.` : "CarrierRef is mandatory.",
            });
          }

          // Vérification pour SubcontractorRef
          if (isPartnerMC && dataST)
            this.processRowsPartnersRoleErrors.push({
              rowIndex,
              column: "SubcontractorRef",
              description: "SubcontractorRef is forbidden for loading operation.",
            });
          break;

        case "D":
          // Vérification pour CarrierRef
          if (!dataMT || (!isPartnerMT && !isPartnerMD)) {
            this.processRowsPartnersRoleErrors.push({
              rowIndex,
              column: "CarrierRef",
              description: dataMT ? `My reference ${myPartnerReference} is mandatory in either CarrierRef or ReceiverRef.` : "CarrierRef is mandatory.",
            });
          }

          // Vérification pour ReceiverRef
          if (!dataMD || (!isPartnerMT && !isPartnerMD)) {
            this.processRowsPartnersRoleErrors.push({
              rowIndex,
              column: "ReceiverRef",
              description: dataMD ? `My reference ${myPartnerReference} is mandatory in either CarrierRef or ReceiverRef.` : "ReceiverRef is mandatory.",
            });
          }

          // Vérification pour SubcontractorRef
          if (isPartnerMD && dataST)
            this.processRowsPartnersRoleErrors.push({
              rowIndex,
              column: "SubcontractorRef",
              description: "SubcontractorRef is forbidden for unloading operation.",
            });
          break;

        case "T":
          // Vérification pour ShipperRef
          if (!dataMC || (!isPartnerMC && !isPartnerMD)) {
            this.processRowsPartnersRoleErrors.push({
              rowIndex,
              column: "ShipperRef",
              description: dataMC ? `My reference ${myPartnerReference} is mandatory in either ShipperRef or ReceiverRef.` : "ShipperRef is mandatory.",
            });
          }

          // Vérification pour ReceiverRef
          if (!dataMD || (!isPartnerMC && !isPartnerMD)) {
            this.processRowsPartnersRoleErrors.push({
              rowIndex,
              column: "ReceiverRef",
              description: dataMD ? `My reference ${myPartnerReference} is mandatory in either ShipperRef or ReceiverRef.` : "ReceiverRef is mandatory.",
            });
          }

          // Vérification pour SubcontractorRef
          if (dataST)
            this.processRowsPartnersRoleErrors.push({
              rowIndex,
              column: "SubcontractorRef",
              description: "SubcontractorRef is forbidden for transfer operation.",
            });
          break;

        default:
          break;
      }
    },

    validateUniqueRefOp(rowIndex, data, errors) {
      // Créer un objet pour stocker les références uniques par type d'opération
      const uniqueRefOpsByType = {
        T: new Set(),
        C: new Set(),
        D: new Set(),
      };

      // Récupérer les index des colonnes TypeOp et RefOp
      const typeOpColumnIndex = this.headers.indexOf("TypeOp");
      const refOpColumnIndex = this.headers.indexOf("RefOp");

      // Parcourir toutes les lignes pour remplir l'objet uniqueRefOpsByType
      for (let i = 0; i < this.rows.length; i++) {
        if (i !== rowIndex) {
          const row = this.rows[i];
          const typeOp = row[typeOpColumnIndex];
          const refOp = row[refOpColumnIndex];

          // Ajouter la référence à l'ensemble correspondant si elle existe
          if (typeOp && refOp) {
            uniqueRefOpsByType[typeOp].add(refOp);
          }
        }
      }

      // Vérifier le type d'opération de la ligne actuelle
      const currentTypeOp = this.rows[rowIndex][typeOpColumnIndex];

      // Fonction pour vérifier si la référence est dupliquée dans les types spécifiés
      const isDuplicateRef = (types) => types.some((type) => uniqueRefOpsByType[type].has(data));
      if (
        (currentTypeOp === "C" && isDuplicateRef(["T", "C"])) ||
        (currentTypeOp === "D" && isDuplicateRef(["T", "D"])) ||
        (currentTypeOp === "T" && isDuplicateRef(["C", "T", "D"]))
      ) {
        errors.push(`RefOp : ${data} is duplicate`);
      }
    },

    validatePalletRowIsNotEmpty(rowIndex) {
      // Supprimer toutes les erreurs existantes pour cette ligne spécifique
      this.processRowsPalletsErrors = this.processRowsPalletsErrors.filter((error) => error.rowIndex !== rowIndex);

      // Récupérer la ligne actuelle et les en-têtes
      const currentRow = this.rows[rowIndex];
      const headers = this.headers;
      const palletHeaders = Array.from(this.headersPallets);

      // Vérification rapide pour déterminer si la ligne est vide
      let isRowEmpty = true;

      // Vérifier chaque en-tête de palette
      for (const header of palletHeaders) {
        // Convertir la valeur en nombre et vérifier si elle est différente de zéro
        if (Number(currentRow[headers.indexOf(header)]) !== 0) {
          isRowEmpty = false; // La ligne n'est pas vide si une valeur non nulle est trouvée
          break; // Sortir de la boucle dès qu'une valeur non nulle est trouvée
        }
      }

      // Si la ligne est vide, ajouter des erreurs pour chaque en-tête de palette
      if (isRowEmpty) {
        // Ajouter des erreurs pour chaque en-tête de palette
        palletHeaders.forEach((header) => {
          this.processRowsPalletsErrors.push({
            rowIndex,
            column: header,
            description: "Operation must have loaded or returned pallets",
          });
        });
      }
    },
    //#endregion

    //#region Data Upload
    uploadData() {
      this.isUploading = true;
      const operations = this.rows.map((row) => this.createOperation(row));
      const data = { Operations: operations };
      (async () => {
        try {
          await ApiService.post("/operation/import", data);
          this.finishProcess(this.ImportStatus.SUCCESS, this.$gettext("Operations imported successfully"));
        } catch (error) {
          this.handleUploadError(error);
        } finally {
          this.isUploading = false;
        }
      })();
    },

    createOperation(row) {
      const { existMC, existMD, existMT, existST, headers } = this;
      const myPartnerRef = this.myPartnerReference;
      const dataMC = existMC ? row[headers.indexOf("ShipperRef")] : null;
      const dataMD = existMD ? row[headers.indexOf("ReceiverRef")] : null;
      const dataMT = existMT ? row[headers.indexOf("CarrierRef")] : null;
      const dataST = existST ? row[headers.indexOf("SubcontractorRef")] : null;
      let myRole = null;
      if (myPartnerRef === dataMC) {
        myRole = "MC";
      } else if (myPartnerRef === dataMD) {
        myRole = "MD";
      } else if (myPartnerRef === dataMT) {
        myRole = "MT";
      } else if (myPartnerRef === dataST) {
        myRole = "ST";
      }
      const operationDate = this.formatDate(row[this.headers.indexOf("DateOp")], "YYYY-MM-DDTHH:mm:ssZ");
      let operation = {
        OperationDate: operationDate,
        OperationID: 0,
        OperationType: row[this.headers.indexOf("TypeOp")],
        OperationCMR: row[this.headers.indexOf("CMR")],
        OperationRef: row[this.headers.indexOf("RefOp")],
        MyRole: myRole,
        POVs: [
          {
            Pallets: [],
            Role: myRole,
          },
        ],
        Partners: [],
        Creation: {
          DateTime: operationDate,
          PartnerID: this.getUser.ClientID,
          TimeStamp: new Date().getTime(),
          Name: this.getUser.ClientName,
        },
        Stream: {
          StreamID: "0",
          Operations: [],
        },
      };
      this.addPartnersToOperation(operation, row);
      this.addPalletsToOperation(operation, row);
      return operation;
    },

    formatDate(date, outputFormat = "YYYY-MM-DDTHH:mm:ssZ") {
      const datePattern = /^(\d{1,2})\/(\d{1,2})\/(\d{2,4})$/;
      let day, month, year;
      const now = new Date();

      // Vérification du format de la date en chaîne
      if (typeof date === "string" && datePattern.test(date)) {
        const [, d, m, y] = date.match(datePattern);
        day = d.padStart(2, "0");
        month = m.padStart(2, "0");
        year = y.length === 2 ? `20${y}` : y; // Conversion des années à deux chiffres
      }
      // Vérification si la date est un objet Date
      else if (date instanceof Date) {
        day = String(date.getDate()).padStart(2, "0");
        month = String(date.getMonth() + 1).padStart(2, "0");
        year = String(date.getFullYear());
      }
      // Retourner la date originale si elle n'est ni une chaîne ni un objet Date
      else {
        return date;
      }

      // Création d'un objet de remplacement pour les formats de date
      const replacements = {
        DD: day,
        MM: month,
        YYYY: year,
        HH: String(now.getHours()).padStart(2, "0"),
        mm: String(now.getMinutes()).padStart(2, "0"),
        ss: String(now.getSeconds()).padStart(2, "0"),
        Z: `+${String(-now.getTimezoneOffset() / 60).padStart(2, "0")}:00`,
      };

      // Remplacement des formats dans la chaîne de sortie
      return outputFormat.replace(/DD|MM|YYYY|HH|mm|ss|Z/g, (match) => replacements[match] || match);
    },

    /**
     * Adds partners to the operation based on row data.
     * @param {Object} operation - Operation object to update.
     * @param {string[]} row - Array of values representing a single operation.
     */
    addPartnersToOperation(operation, row) {
      [
        { column: "ShipperRef", role: "MC" },
        { column: "ReceiverRef", role: "MD" },
        { column: "CarrierRef", role: "MT" },
        { column: "SubcontractorRef", role: "ST" },
      ].forEach(({ column, role }) => {
        const index = this.headers.indexOf(column);
        if (index !== -1) {
          const partnerRef = row[index];
          if (partnerRef) {
            const partner = Array.from(this.partnerReferencesData).find((p) => p.Reference === partnerRef);
            if (partner) {
              operation.Partners.push({
                PartnerID: partner.Id,
                PartnerRole: role,
                PartnerName: partner.Name,
              });
            }
          }
        }
      });
    },

    /**
     * Adds pallets to the operation based on row data and headers.
     * @param {Object} operation - Operation object to update.
     * @param {string[]} row - Array of values representing a single operation.
     */
    addPalletsToOperation(operation, row) {
      this.prefixesHeadersPallets.forEach((palletColumn) => {
        const currentPalet = this.getPallets.find((p) => p.PalletRef === palletColumn);
        if (!currentPalet) return; // Si aucune palette correspondante n'est trouvée, passer à l'itération suivante (normalement jamais le cas)
        let pallet = {
          PalletID: currentPalet.PalletID,
          PalletName: currentPalet.PalletName,
          FP: row[this.headers.indexOf("+" + palletColumn)],
          FR: row[this.headers.indexOf("-" + palletColumn)],
        };

        // Vérifier si au moins une des quantités est différente de zéro avant d'ajouter la palette
        if (pallet.FP !== "0" || pallet.FR !== "0") {
          operation.POVs[0].Pallets.push(pallet);
        }
      });
    },
    //#endregion

    /** @param {Error} error */
    handleUploadError(error) {
      // Ignorer les erreurs de serveur interne
      if (error.response?.status === 500) return;

      // Initialiser le message d'erreur
      let errorMessage = this.$gettext("Failed to upload data:");

      // Vérifier la présence de données d'erreur dans la réponse
      const errorData = error.response?.data;
      if (errorData) {
        if (Array.isArray(errorData.Errors)) {
          const errors = errorData.Errors;
          for (let i = 0, len = errors.length; i < len; i++) {
            errorMessage += `\n${errors[i].OperationRef}: ${errors[i].Error}`;
          }
        } else {
          errorMessage += ` ${errorData}`;
        }
      } else {
        errorMessage += ` ${error.message}`;
      }

      this.finishProcess(this.ImportStatus.ERROR, errorMessage);
    },
    finishProcess(status, message) {
      this.cancelUpload();

      // Shows a brief notification message.
      Swal.mixin({
        toast: true,
        icon: status,
        title: message,
        position: "top-end",
        showConfirmButton: false,
        timer: 10000,
      }).fire();
    },

    /** Resets all states and data related to the file upload. */
    cancelUpload() {
      this.columnsPalletCanBeReturned = new Set();
      this.columnsPalletCanNotBeReturned = new Set();
      this.myPartnerReference = null;
      this.partnerReferences = new Set();
      this.partnerReferencesData = new Set();
      this.myPartner = {
        Reference: null,
        Id: null,
        Name: null,
      };
      this.existMC = false;
      this.existMD = false;
      this.existMT = false;
      this.existST = false;
      this.prefixesHeadersPallets = new Set();
      this.headersPallets = new Set();
      this.uniqueHeaders = new Set();
      this.headers = [];
      this.rows = [];
      this.processErrors = [];
      this.processRowsPalletsErrors = [];
      this.processRowsPartnersRoleErrors = [];
      this.$emit("fileDropped", false);
    },

    //#region Data Processing
    processRows() {
      // Référence locale pour éviter les accès répétés
      const headers = this.headers;
      const rows = this.rows;

      // Traiter les lignes et les cellules
      for (let i = 0, rowsLen = rows.length; i < rowsLen; i++) {
        const row = rows[i];
        for (let j = 0, cellsLen = row.length; j < cellsLen; j++) {
          this.updateCell(i, headers[j], row[j]);
        }
      }
    },

    /** @param {number} rowIndex @param {string} column @param {string} data */
    updateCell(rowIndex, column, data) {
      // Supprimer toutes les erreurs existantes pour cette cellule spécifique
      this.processErrors = this.processErrors.filter((error) => !(error.column === column && error.rowIndex === rowIndex));
      let errors = []; // Tableau temporaire pour stocker les erreurs

      // Vérifications générales sur les données saisies
      if (/[!@#$%^&*()_+\=\[\]{};'"\\|,<>?]+/.test(data)) {
        errors.push(`Special characters are not allowed.`);
      }
      // Vérifications spécifiques selon le type de colonne
      else if (column === "RefOp") {
        if (data.length > 40) {
          errors.push(`Field "${column}" must not exceed 40 characters.`);
        } else if (data.length == 0) {
          errors.push(`Field "${column}" is mandatory.`);
        }
        // Validation de la référence opérationnelle unique
        this.validateUniqueRefOp(rowIndex, data, errors);
      } else if (column === "CMR") {
        if (data.length > 20) {
          errors.push(`Field "${column}" must not exceed 20 characters.`);
        }
        // } else if (column === "TypeOp") {
        //   // Vérification des types d'opération valides
        //   if (!["T","C","D"].includes(data)) errors.push(`Field "${data}" is different from 'C', 'D' or 'T'.`);
      } else if (column === "DateOp") {
        // Vérification du format de la date DD/MM/YYYY
        const isValidDateFormat = /^\d{2}\/\d{2}\/\d{4}$/.test(data);
        if (isValidDateFormat) {
          const [day, month, year] = data.split("/").map(Number); // Convertir les valeurs en nombres
          const cellDate = new Date(year, month - 1, day); // Créer un objet Date (mois - 1 car les mois commencent à 0)

          // Vérifier si le jour et le mois sont valides
          const isValidDay = day > 0 && day <= 31; // Vérifie que le jour est entre 1 et 31
          const isValidMonth = month > 0 && month <= 12; // Vérifie que le mois est entre 1 et 12
          const isLeapYear = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
          const isValidFebruary = month === 2 && day <= (isLeapYear ? 29 : 28);
          const isValidOtherMonth = month !== 2 && ([4, 6, 9, 11].includes(month) ? day <= 30 : day <= 31);

          // Vérifier si la date est valide en fonction des mois
          const isValidDate = isValidDay && isValidMonth && (isValidFebruary || isValidOtherMonth);

          // Vérifier si la date est dans le futur
          if (isValidDate) {
            if (cellDate > new Date()) {
              errors.push("Date cannot be in the future");
            }
          } else {
            errors.push("Invalid date: please check the day and month values.");
          }
        } else {
          errors.push("Invalid date format. Please use DD/MM/YYYY");
        }
      } else if (this.PartnerRefs.has(column)) {
        if (data.length > 20) {
          errors.push(`Field "${column}" must not exceed 20 characters.`);
        }

        // Vérification des références partenaires
        if (data.length > 0 && !this.partnerReferences.has(data)) errors.push(`Partner Reference : "${data}" does not exist in the system.`);

        this.validatePartnersRole(rowIndex);
      } else if (column.startsWith("-") || column.startsWith("+")) {
        // Validation des données de palettes
        const palletQuantity = Number(data);
        const PalletColumn = column.slice(1); // Enlever le préfixe "+" ou "-"

        const indexCurrentColumn = this.headers.indexOf(column);
        const typeOp = this.rows[rowIndex][this.headers.indexOf("TypeOp")];

        // Vérifier si la quantité est un entier valide
        if (!Number.isInteger(palletQuantity) || Math.abs(palletQuantity) > this.maximumQuantityPallets) {
          errors.push(
            `Quantity of pallets for "${PalletColumn}" must be an integer between -${this.maximumQuantityPallets} and ${this.maximumQuantityPallets}.`
          );
        }

        // Réinitialiser la valeur si certaines conditions sont remplies
        if (
          data.length === 0 ||
          (this.columnsPalletCanNotBeReturned.has(PalletColumn) && column.startsWith("-")) ||
          (this.columnsPalletCanBeReturned.has(PalletColumn) && column.startsWith("-") && typeOp === "T")
        ) {
          this.rows[rowIndex][indexCurrentColumn] = "0";
        }

        this.validatePalletRowIsNotEmpty(rowIndex);
      }

      // Ajouter toutes les erreurs collectées à processErrors
      for (let i = 0, len = errors.length; i < len; i++) {
        this.processErrors.push({
          rowIndex: rowIndex,
          column: column,
          description: errors[i],
        });
      }
    },
    debouncedUpdate(event, rowIndex, columnHeader) {
      clearTimeout(this.updateTimeout);
      this.updateTimeout = setTimeout(() => {
        // Ce bloc s'exécute 500ms après la dernière frappe :
        // 1. On récupère la valeur saisie
        // 2. On met à jour le tableau de données
        // 3. On appelle updateCell pour traiter le changement
        const value = event.target.value;
        this.$set(this.rows[rowIndex], this.headers.indexOf(columnHeader), value);
        this.updateCell(rowIndex, columnHeader, value);
      }, 500);
    },
    //#endregion
  },
};
</script>

<style>
.table-container {
  border: solid 1px whitesmoke;
}
.table-custom {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.table-custom thead {
  background-color: white;
  position: sticky;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1);
  top: 0;
  z-index: 10;
}
.left-stick {
  background-color: rgb(241, 241, 241);
  position: sticky;
  left: 0;
  z-index: 2;
}
.table-custom th {
  font-weight: 600;
  color: hsl(13, 2%, 55%);
  padding: 8px;
  white-space: nowrap;
}
.index-rows {
  font-weight: 600;
  color: hsl(13, 2%, 55%);
  text-align: center;
  padding-inline: 3px;
}
.cell-operation {
  padding-inline: 8px;
  padding-top: 7px;
  resize: none;
  word-break: break-word;
  display: flex;
  line-height: 100%;
}
td[contenteditable="true"] {
  outline: none;
}
.error-button,
.action-button {
  background: none;
  border: 2px solid transparent;
  padding: 5px;
  margin: 0;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-button:hover,
.error-button.active {
  border: 2px solid #e74a3b;
}
.action-button-upload:hover {
  border: 2px solid #28a745;
  background-color: rgba(40, 167, 69, 0.1);
}
.action-button-refresh:hover {
  border: 2px solid #007bff;
  background-color: rgba(0, 123, 255, 0.1);
}
.action-button-cancel:hover {
  border: 2px solid #dc3545;
  background-color: rgba(220, 53, 69, 0.1);
}
</style>
